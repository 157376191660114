<template>
  <div class="game-card">
    <img :src="game.box_art_url" :alt="game.name" class="game-card-image">
    <div class="game-card-title">{{ game.name }}</div>
    <div class="game-card-votes" v-if="game.votes">
      <b><Number :value="game.votes" /></b>
      vote{{ game.votes > 1 ? 's' : '' }}
    </div>
  </div>
</template>

<script>
import Number from './Number.vue';

export default {
  name: 'GameCard',
  props: {
    game: Object,
  },
  components: {
    Number,
  },
};
</script>

<style scoped>
.game-card {
  font-size: 14px;
  text-align: center;
}

.game-card-image {
  display: block;
  width: 100%;
  border-radius: 16px;
  margin-bottom: 12px;
}

.game-card-title {
  font-weight: 600;
}
</style>
