<template>
  <div id="app" :route="$route.name" v-if="isLive" @click="menuOpen = false; accountOpen = false">
    <header v-if="user !== undefined" :closed="navClosed">
      <div class="wrapper">
        <div class="header-container">
          <router-link to="/" class="header-logo">
            <img src="@/assets/logo.svg" alt="What Should I Play?" />
          </router-link>
          <nav>
            <router-link to="/">Home</router-link>
            <router-link to="/news">News</router-link>
            <router-link to="/leaderboards" disabled>Leaderboards</router-link>
            <router-link to="/stats" disabled>Stats</router-link>
            <router-link to="/about">About</router-link>
          </nav>
        </div>
        <button class="icon-button" @click.stop="menuOpen = !menuOpen; accountOpen = false">
          <i class="ic large ic-menu"></i>
        </button>
        <div class="header-actions" :open="menuOpen">
          <router-link class="btn header-btn create-btn" :to="user ? '/polls/new' : '/login'">
            Create a poll
          </router-link>
          <router-link class="btn header-btn outlined" to="/polls">
            Browse public polls
          </router-link>
          <a class="social-btn" href="https://x.com/wsip_app" target="_blank" rel="noopener noreferrer">
            <Icon name="x" size="s" />
          </a>
          <router-link class="nav-link mobile-nav" to="/">Home</router-link>
          <router-link class="nav-link mobile-nav" to="/news">News</router-link>
          <router-link class="nav-link mobile-nav" to="/leaderboards" disabled>Leaderboards</router-link>
          <router-link class="nav-link mobile-nav" to="/stats" disabled>Stats</router-link>
          <router-link class="nav-link mobile-nav" to="/about">About</router-link>
          <router-link class="nav-link mobile" :to="user ? '/polls/new' : '/login'">Create a poll</router-link>
          <router-link class="nav-link mobile" to="/polls">Browse public polls</router-link>
          <div v-if="user" class="btn ghost user-btn" @click.stop="accountOpen = true">
            Account
            <img :src="user.avatar" :alt="user.name" :title="user.name" class="avatar">
            <div class="window-container" :open="accountOpen">
              <div class="window account">
                <div class="window-title">Connections</div>
                <a class="window-option" @click="connectWithTwitch">
                  <i class="ic small ic-twitch"></i>
                  <div class="window-option-content">
                    <div class="window-option-title" v-if="user.twitch || user.twitch_id || user.type === 'twitch'">{{ user.twitch ? user.twitch.name : user.name }}</div>
                    <div class="window-option-info">Twitch</div>
                  </div>
                  <div class="window-option-action">
                    <i class="ic small ic-check" v-if="user.twitch || user.twitch_id || user.type === 'twitch'"></i>
                    <ToggleButton v-else />
                  </div>
                </a>
                <a class="window-option" @click="connectWithDiscord">
                  <i class="ic small ic-discord"></i>
                  <div class="window-option-content">
                    <div class="window-option-title" v-if="user.discord || user.discord_id || user.type === 'discord'">{{ user.discord ? user.discord.name : user.name }}</div>
                    <div class="window-option-info">Discord</div>
                  </div>
                  <div class="window-option-action">
                    <i class="ic small ic-check" v-if="user.discord || user.discord_id || user.type === 'discord'"></i>
                    <ToggleButton v-else />
                  </div>
                </a>
                <router-link class="window-option separator" to="/profile/polls">
                  <Icon name="poll" size="s" />
                  <div class="window-option-content">
                    My polls
                  </div>
                </router-link>
                <router-link class="window-option" to="/profile/votes">
                  <Icon name="vote" size="s" />
                  <div class="window-option-content">
                    My votes
                  </div>
                </router-link>
                <a class="window-option separator" @click="logOut">
                  <Icon name="signout" size="s" />
                  <div class="window-option-content">Sign out</div>
                </a>
                <a class="window-option subdued separator" @click="resetOpen = true">
                  <i class="ic small ic-reset"></i>
                  <div class="window-option-content">
                    Reset account
                  </div>
                </a>
                <a class="window-option danger" @click="deleteOpen = true">
                  <i class="ic small ic-delete-r"></i>
                  <div class="window-option-content">
                    Delete account
                  </div>
                </a>
              </div>
            </div>
          </div>
          <router-link v-else class="btn ghost" to="/login">
            <i class="ic ic-signin"></i>
            Sign in
          </router-link>
        </div>
      </div>
    </header>
    <div class="router-container">
      <router-view v-if="user !== undefined" />
    </div>
    <footer v-if="user !== undefined">
      <div class="wrapper">
        <div class="footer-container">
          <div class="col m">
            <router-link to="/">
              <img src="@/assets/logo.svg" alt="What Should I Play?" class="footer-logo">
            </router-link>
            <a class="ph-embed" href="https://www.producthunt.com/posts/what-should-i-play?utm_source=badge-featured&utm_medium=badge&utm_souce=badge-what&#0045;should&#0045;i&#0045;play" target="_blank"><img src="https://api.producthunt.com/widgets/embed-image/v1/featured.svg?post_id=443816" alt="What&#0032;Should&#0032;I&#0032;Play&#0063; - Create&#0032;a&#0032;Poll&#0044;&#0032;Community&#0032;Decides&#0032;on&#0032;your&#0032;Next&#0032;Epic&#0032;Game&#0033; | Product Hunt" style="width: 180px" width="250" height="54" /></a>
          </div>
          <div class="col m">
            <router-link class="footer-item" to="/">Home</router-link>
            <router-link class="footer-item" to="/news">News</router-link>
            <router-link class="footer-item" to="/leaderboards" disabled>Leaderboards</router-link>
            <router-link class="footer-item" to="/stats" disabled>Stats</router-link>
          </div>
          <div class="col m">
            <router-link class="footer-item" to="/about">About</router-link>
            <router-link class="footer-item" to="/patch-notes">Patch Notes</router-link>
            <router-link class="footer-item" to="/privacy-policy">Privacy Policy</router-link>
            <router-link class="footer-item" to="/cookie-policy">Cookie Policy</router-link>
          </div>
          <div class="col m">
            <div class="footer-item">Follow us</div>
            <a href="https://x.com/wsip_app" target="_blank" rel="noopener noreferrer" class="footer-item">
              <Icon name="x" size="s" />
              @wsip_app
            </a>
          </div>
          <div class="col x">
            <div class="footer-item">Give us your feedback</div>
            <div class="form-success" v-if="feedbackSent">
              Thank you for sharing your feedback with us! ❤️
            </div>
            <form @submit.prevent="sendFeedback" v-else>
              <div class="field">
                <input class="input" type="email" maxlength="255" placeholder="Your email" v-model="feedbackEmail" required>
              </div>
              <div class="field">
                <textarea class="input" placeholder="How can we improve WSIP?" maxlength="1500" v-model="feedbackMessage" required></textarea>
              </div>
              <button type="submit" class="btn" :disabled="loading || !feedbackEmail || !feedbackMessage">Send</button>
            </form>
          </div>
        </div>
        <div class="footer-bottom">
          <div class="signature">
            <div><b>What Should I Play?</b> Version 1.2.5</div>
            <div>Made by <a href="https://blackpugstudio.com" target="_blank" rel="noopener noreferrer">Black Pug Studio</a> - Because we love gaming communities!</div>
            <div class="legal">‘What should I Play?’ is not affiliated with any third-party game, gamer, or gaming community.</div>
          </div>
          <div class="copyright">© {{ currentYear }} - Black Pug Studio Ltd. - All rights reserved.</div>
        </div>
      </div>
    </footer>
    <div class="overlay" v-if="resetOpen" @click="resetOpen = false">
      <div class="dialog" @click.stop>
        <div class="dialog-title">Wait wait!</div>
        <div class="dialog-content">
          Are you sure you want to reset your account?<br><br>
          All your polls and votes will be deleted.
        </div>
        <div class="dialog-actions">
          <button class="btn" @click="resetOpen = false" :disabled="loading">Take me back! This was a mistake...</button>
          <button class="btn outlined" @click="resetAccount" :disabled="loading">Yes, I'm sure. Reset my account</button>
        </div>
      </div>
    </div>
    <div class="overlay" v-if="deleteOpen" @click="deleteOpen = false">
      <div class="dialog" @click.stop>
        <div class="dialog-title">Wait wait!</div>
        <div class="dialog-content">
          Are you sure you want to delete your account?<br><br>
          All your data will be deleted permanently and you won't be able to recover your polls or votes.
        </div>
        <div class="dialog-actions">
          <button class="btn" @click="deleteOpen = false" :disabled="loading">Take me back! This was a mistake...</button>
          <button class="btn outlined" @click="deleteAccount" :disabled="loading">Yep, I'm done. Delete my account</button>
        </div>
      </div>
    </div>
    <div class="prompt" :open="promptOpen">
      <div class="prompt-container">
        <div class="prompt-content" v-if="prompt">
          <div class="prompt-title">{{ prompt.title }}</div>
          <div class="prompt-message">{{ prompt.message }}</div>
        </div>
      </div>
    </div>
  </div>
  <div id="app" v-else>
    <div class="main-container">
      <img src="@/assets/logo.svg" alt="What Should I Play?" class="main-logo">
      <h2>Coming very soon!</h2>
      <p>{{ count }} people waiting...</p>
      <button v-if="!hasJoined" class="btn" :disabled="loading" @click="joinWaiting">I can't wait! 🤩</button>
    </div>
    <footer>
      <div class="social">
        <div><b>Stay connected!</b></div>
        <div>Follow us for all the latest updates.</div>
        <a href="https://x.com/wsip_app" target="_blank" rel="noopener noreferrer">
          <Icon name="x" size="s" />
          @wsip_app
        </a>
      </div>
      <div class="version">Version 1.1.0</div>
      <div class="copyright">Made by <a href="https://blackpugstudio.com" target="_blank" rel="noopener noreferrer">Black Pug Studio</a> - Because we love gaming communities!</div>
    </footer>
  </div>
</template>

<script>
import { signOut } from 'firebase/auth';
import { mapState } from 'vuex';
import {
  collection, doc, onSnapshot, setDoc,
} from 'firebase/firestore';
import { auth, db } from './api';
import ToggleButton from './components/ToggleButton.vue';
import { client } from './api/api';
import { removeSearchParam } from './utils/history';
import Icon from './components/Icon.vue';

// const hasCode = window.location.search.includes('code=P1AlAWHqLhZlHYLBX7Mkiyq8u8l1');

export default {
  name: 'App',
  data() {
    return {
      count: 0,
      waitingList: [],
      loading: false,
      resetOpen: false,
      deleteOpen: false,
      menuOpen: false,
      accountOpen: window.location.hash.includes('account'),
      feedbackSent: false,
      navClosed: false,
      feedbackMessage: '',
      feedbackEmail: '',
    };
  },
  components: { ToggleButton, Icon },
  computed: {
    ...mapState(['user', 'authUser', 'prompt', 'promptOpen']),
    isLive() {
      return true;
      // return hasCode || ['P1AlAWHqLhZlHYLBX7Mkiyq8u8l1', 'wELqdSIdLmVwZPBBNhPhvjBvuEY2', 'gV7MG0hFEoYzivsPiz5OblUGIcw1', '5cedsxpa7sZvAVAj0W11xLTDHwc2'].includes(this.user?.id || this.authUser?.uid);
    },
    hasJoined() {
      return this.waitingList.includes(this.authUser?.uid);
    },
    currentYear() {
      return new Date().getFullYear();
    },
  },
  watch: {
    user() {
      this.checkRoute();
    },
    $route() {
      this.checkRoute();
      window.scrollTo({ top: 0 });
    },
  },
  methods: {
    checkRoute() {
      if (this.user === null && ['poll-new', 'profile-polls', 'profile-votes', 'poll-edit'].includes(this.$route.name)) {
        this.$router.push('/');
      }
    },
    async resetAccount() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      await client.post('/account/reset');
      this.loading = false;
      this.resetOpen = false;
      window.location.href = `${window.location.href}?account_reset=1`;
    },
    async deleteAccount() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      await client.post('/account/delete');
      this.loading = false;
      this.deleteOpen = false;
    },
    async sendFeedback() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      try {
        await client.post('/feedback', {
          email: this.feedbackEmail,
          message: this.feedbackMessage,
        });
        this.feedbackEmail = '';
        this.feedbackMessage = '';
        this.feedbackSent = true;
      } catch (error) {
        console.log('FEEDBACK_ERROR', error.message);
      }
      this.loading = false;
    },
    async connectWithTwitch() {
      const isConnected = this.user.twitch || this.user.twitch_id || this.user.type === 'twitch';
      if (this.loading || isConnected) {
        return;
      }
      this.loading = true;
      try {
        const { url } = await client.get('/twitch/connect');
        window.location.href = url;
      } catch (error) {
        console.warn(error.message);
        this.loading = false;
      }
    },
    async connectWithDiscord() {
      const isConnected = this.user.discord || this.user.discord_id || this.user.type === 'discord';
      if (this.loading || isConnected) {
        return;
      }
      this.loading = true;
      try {
        const { url } = await client.post('/discord/connect');
        window.location.href = url;
      } catch (error) {
        console.warn(error.message);
        this.loading = false;
      }
    },
    logOut() {
      signOut(auth);
    },
    getCount() {
      onSnapshot(collection(db, 'waiting'), (docs) => {
        this.count = docs.size;
        this.waitingList = docs.docs.map((d) => d.id);
      });
    },
    async joinWaiting() {
      this.loading = true;
      await setDoc(doc(db, `waiting/${this.authUser.uid}`), {
        id: this.authUser.uid,
        created: new Date(),
      });
    },
  },
  mounted() {
    if (!this.isLive) {
      this.getCount();
    }
    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    let scrollValue = 0;
    window.addEventListener('scroll', () => {
      this.navClosed = window.scrollY > 0 && scrollValue < window.scrollY;
      scrollValue = window.scrollY;
    });
    window.addEventListener('resize', () => {
      const screenSize = window.screen.width;
      if (window.innerWidth > 1440 && screenSize > 1920) {
        const fixedSize = 2440;
        const ratio = Math.max(screenSize / (fixedSize + Math.max(fixedSize - window.innerWidth, 0)), 1);
        const invertedRatio = 1 / ratio;
        document.body.style.zoom = ratio;
        document.documentElement.style.setProperty('--window-ratio-fix', isSafari ? 1 : invertedRatio);
      } else {
        document.body.style.zoom = null;
        document.documentElement.style.setProperty('--window-ratio-fix', 1);
      }
      const pollItems = document.querySelectorAll('.poll-item .poll-item-title');
      pollItems.forEach((pollItem) => {
        pollItem.style.height = null;
      });
      pollItems.forEach((pollItem) => {
        const rect = pollItem.getBoundingClientRect();
        pollItems.forEach((item) => {
          const irect = item.getBoundingClientRect();
          if (rect.top === irect.top && rect.height !== irect.height) {
            item.style.height = `${Math.max(rect.height, irect.height)}px`;
          }
        });
      });
    });
    window.dispatchEvent(new Event('resize'));
    if (removeSearchParam('account_reset', '1')) {
      setTimeout(() => {
        this.$store.commit('setPrompt', { title: 'Notification', message: 'Your account was cleared, and your polls and votes deleted.' });
      }, 1000);
    }
    window.scrollTo({ top: 0 });
  },
};
</script>

<style>
:root {
  --window-ratio-fix: 1;
}

* {
  box-sizing: border-box;
  outline: none;
}

html, body {
  margin: 0;
  padding: 0;
}

button {
  border: none;
  background: none;
  color: inherit;
  font: inherit;
  cursor: pointer;
  text-align: left;
  -webkit-appearance: none;
  appearance: none;
}

h1, h2, h3, p {
  margin: 0;
}

.btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  flex-shrink: 0;
  background: #F36;
  gap: 8px;
  height: 42px;
  border: none;
  padding: 0 24px;
  border-radius: 24px;
  white-space: nowrap;
  color: #fff;
  text-decoration: none;
  font: 500 16px/1 all-round-gothic;
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.btn.twitch {
  background: #9146ff;
}

.btn.discord {
  background: #5865f2;
}

.btn.ghost {
  background: transparent;
}

.btn.outlined {
  border: 1px solid #F36;
  background: transparent;
}

.btn.outlined.default {
  border-color: #fff;
}

.btn.outlined.danger {
  border-color: #F27272;
  color: #F27272;
}

.btn[disabled] {
  opacity: 0.5;
}

.feedback-button {
  position: sticky;
  bottom: 16px;
  right: 16px;
  margin: auto 0 auto auto;
  display: block;
  z-index: 1;
}

.prompt {
  position: fixed;
  z-index: 2;
  width: 320px;
  bottom: 0;
  right: 0;
  padding: 16px;
  overflow: hidden;
  pointer-events: none;
}

.prompt-container {
  padding: 16px 24px;
  background: rgba(31, 29, 44, 0.8);
  border-radius: 16px;
  transition: all 0.2s ease-in;
  transform: translateY(calc(100% + 16px));
  opacity: 0;
}

.prompt[open] .prompt-container {
  transform: none;
  opacity: 1;
}

.prompt .prompt-title {
  font-weight: bold;
  margin-bottom: 8px;
  color: #F36;
}

.window-container {
  position: absolute;
  top: 100%;
  right: 0;
  padding-top: 24px;
  visibility: hidden;
  pointer-events: none;
}

.window {
  position: relative;
  width: 290px;
  border-radius: 16px;
  background: #1F1D2C;
  padding: 16px 18px;
  z-index: 2;
  overflow: hidden;
}

.window-container[open] {
  visibility: visible;
  pointer-events: all;
}

.window-title {
  padding: 12px 32px;
  font-weight: 600;
}

.window-option {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 0 16px;
  min-height: 60px;
}

.window-option.separator {
  background: url(./assets/ic-h-line.svg) repeat-x left top/auto 2.5px;
  padding-top: 2px;
}

.window-option:hover {
  background-color: #2C2B3B;
}

.window-option-content {
  flex-grow: 1;
}

.window-option.subdued .window-option-content {
  color: rgba(255, 255, 255, 0.69);
}

.window-option.subdued .icon {
  color: rgba(255, 255, 255, 0.69);
}

.window-option.danger .window-option-content {
  color: #F27272;
}

.window-option.router-link-active .window-option-content,
.window-option.router-link-active .icon {
  color: #FC0;
}

.window-option-title {
  color: #fff;
  margin-bottom: 8px;
}

.window-option-info {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.69);
}

.router-container {
  min-height: calc(100vh * var(--window-ratio-fix) - 600px);
}

.input {
  height: 42px;
  background: #393652;
  border-radius: 24px;
  width: 100%;
  color: #fff;
  font: inherit;
  border: none;
  padding: 0 16px;
  font-weight: 400;
  -webkit-appearance: none;
  appearance: none;
}

.input::placeholder {
  color: rgba(255, 255, 255, 0.69);
  opacity: 1;
}

.input[disabled] {
  opacity: 0.5;
}

.input.auto {
  width: auto;
  text-align: center;
  font-weight: 600;
}

select.input {
  padding-right: 28px;
  font-weight: 600;
  background: #393652 url(./assets/ic-chevron-down.svg) no-repeat 92% center/20px auto;
}

textarea.input {
  height: 110px;
  resize: none;
  padding: 12px 16px;
}

.icon-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  background: none;
  border: none;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  flex-shrink: 0;
}

.icon-button.l {
  width: 32px;
  height: 32px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px;
  z-index: 100;
  background: rgba(31, 29, 44, 0.69);
}

.overlay .dialog {
  background: rgba(31, 29, 44, 0.8);
  border-radius: 16px;
  padding: 64px 48px;
  width: 512px;
  max-width: 100%;
  text-align: center;
}

.overlay .dialog-title {
  position: relative;
  font-size: 30px;
  font-family: chantal;
  color: #FC0;
}

.overlay .dialog .field {
  margin-top: 24px;
}

.overlay .dialog-title::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  width: 46px;
  height: 9px;
  margin: auto;
  background: url(./assets/title-art.svg) no-repeat center/contain;
}

.overlay .dialog-content {
  margin: 32px 0;
}

.overlay .dialog-message {
  margin-top: 24px;
  margin-bottom: -24px;
  color: rgba(255, 255, 255, 0.69);
}

.overlay .dialog-actions {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
}

.page-title {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-bottom: 64px;
}

.page-title h1 {
  position: relative;
  font-family: chantal;
  font-size: 24px;
  color: #FC0;
}

.page-title h1:after {
  content: '';
  position: absolute;
  top: 100%;
  right: 0;
  width: 46px;
  height: 10px;
  background: url(./assets/title-art.svg) no-repeat center/contain;
}

.user-btn {
  gap: 12px;
  font-weight: 500;
  padding: 8px 0;
  text-align: left;
}

.user-btn .avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  object-fit: cover;
}

.share-box {
  display: flex;
  align-items: center;
  border: 1px solid #F36;
  border-radius: 28px;
}

.share-box .share-link {
  position: relative;
  flex-grow: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 24px 0 36px;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.loader {
  font-size: 2px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(242, 114, 114, 0.2);
  border-right: 1.1em solid rgba(242, 114, 114, 0.2);
  border-bottom: 1.1em solid rgba(242, 114, 114, 0.2);
  border-left: 1.1em solid #FC0;
  transform: translateZ(0);
  animation: load8 1s infinite linear;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

a {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

#app {
  position: relative;
  font-family: all-round-gothic, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
  min-height: calc(100vh * var(--window-ratio-fix));
  background: url(./assets/wsip-bg.png) no-repeat top center/100vw auto;
  padding-top: 84px;
}

#app[route="polls"],
#app[route="poll"] {
  background-image: url(./assets/wsip-poll-bg.png);
}

.app-container {
  position: relative;
}

.main-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  min-height: calc(100vh - 106px);
  padding: 32px;
}

.main-container .main-logo {
  width: 360px;
  max-width: 100%;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 12px 0;
  background: #1F1D2C;
  z-index: 2;
  transition: transform 0.2s ease-out;
}

header .wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

header[closed] {
  transform: translateY(-100%);
}

header .header-container {
  display: flex;
  align-items: center;
}

header nav {
  display: flex;
  align-items: center;
  gap: 32px;
}

header nav .router-link-exact-active {
  color: #F36;
}

header a[disabled] {
  opacity: 0.46;
  pointer-events: none;
}

header .header-logo {
  margin-right: 48px;
}

header .header-logo img {
  display: block;
  width: 48px;
  height: 48px;
  object-fit: contain;
}

header .header-actions {
  display: flex;
  align-items: center;
  gap: 24px;
}

header .header-actions .btn.ghost {
  padding: 0;
}

header .nav-link {
  position: relative;
  margin-right: 32px;
  font-weight: 500;
  flex-shrink: 0;
}

header .nav-link.mobile,
header .nav-link.mobile-nav {
  display: none;
}

header .icon-button {
  display: none;
  width: 48px;
  height: 48px;
}

header .nav-link.router-link-exact-active {
  color: #F36;
}

footer {
  background: #1F1D2C;
  padding: 40px 0;
  margin-top: 64px;
}

.wrapper {
  width: 1440px;
  margin: auto;
  max-width: 100%;
  padding: 0 48px;
}

footer .footer-container {
  display: flex;
  padding: 0 32px 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.23);
  margin-bottom: 24px;
}

footer .col.m {
  width: 17%;
}

footer .col.x {
  flex-grow: 1;
}

footer .footer-item {
  display: block;
  margin-bottom: 20px;
  transition: color 0.2s ease-out;
}

footer a.footer-item:hover {
  color: #F36;
}

footer .footer-item[disabled] {
  pointer-events: none;
  color: rgba(255, 255, 255, 0.46);
}

footer .footer-item[disabled]::after {
  content: 'Coming soon';
  display: inline;
  font-size: 12px;
  margin-left: 12px;
  font-style: italic;
}

footer .footer-logo {
  display: block;
  width: 107px;
  margin-top: 24px;
  margin-bottom: 48px;
}

footer .field {
  margin-bottom: 12px;
}

footer form .btn {
  width: 100%;
  height: 48px;
  margin-top: 12px;
  font-weight: 700;
}

footer form .btn[disabled] {
  background: #333;
  opacity: 1;
  color: rgba(255, 255, 255, 0.46);
}

footer .input {
  background: none;
  border-radius: 2px;
  border: 1px solid rgba(255, 255, 255, 0.23);
  font-weight: normal;
  height: 32px;
  font-size: 14px;
}

footer .input::placeholder {
  color: #FFF;
  opacity: 0.69;
}

footer textarea.input {
  height: 64px;
}

footer .footer-bottom {
  display: flex;
  justify-content: space-between;
  padding: 0 32px;
  font-size: 14px;
}

footer .footer-bottom a {
  font-weight: 600;
  text-decoration: underline;
}

footer .footer-bottom .legal {
  font-size: 12px;
  opacity: 0.46;
  margin-top: 24px;
  font-style: italic;
}

.ic {
  display: inline-block;
  width: 24px;
  height: 24px;
  vertical-align: middle;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  flex-shrink: 0;
}

.ic.small {
  width: 18px;
  height: 18px;
}

.ic.large {
  width: 32px;
  height: 32px;
}

.ic.ic-controller {
  background-image: url(./assets/ic-controller.svg);
}

.ic.ic-share {
  background-image: url(./assets/ic-share.svg);
}

.ic.ic-signout {
  background-image: url(./assets/ic-signout.svg);
}

.ic.ic-twitch {
  background-image: url(./assets/ic-twitch.svg);
}

.ic.ic-edit {
  background-image: url(./assets/ic-edit.svg);
}

.ic.ic-delete {
  background-image: url(./assets/ic-delete.svg);
}

.ic.ic-signin {
  background-image: url(./assets/ic-signin.svg);
}

.ic.ic-discord {
  background-image: url(./assets/ic-discord.svg);
}

.ic.ic-check {
  background-image: url(./assets/ic-check.svg);
}

.ic.ic-search {
  background-image: url(./assets/ic-search.svg);
}

.ic.ic-reset {
  background-image: url(./assets/ic-reset.svg);
}

.ic.ic-delete-r {
  background-image: url(./assets/ic-delete-r.svg);
}

.ic.ic-menu {
  background-image: url(./assets/ic-menu.svg);
}

.ic.ic-close {
  background-image: url(./assets/ic-close.svg);
}

.ic.ic-x {
  background-image: url(./assets/ic-x.svg);
}

.ic.ic-filter {
  background-image: url(./assets/ic-filter.svg);
}

.ic.ic-handle {
  background-image: url(./assets/ic-handle.svg);
}

.ic.ic-list {
  background-image: url(./assets/ic-list.svg);
}

.ic.ic-info {
  background-image: url(./assets/ic-info.svg);
}

.ic.ic-nuke {
  background-image: url(./assets/ic-nuke.svg);
}

.ic.ic-view {
  background-image: url(./assets/ic-view.svg);
}

.ic.ic-refresh {
  background-image: url(./assets/ic-refresh.svg);
}

.ic.ic-refresh-w {
  background-image: url(./assets/ic-refresh-w.svg);
}

.ic.ic-chevron-left {
  background-image: url(./assets/ic-chevron-left.svg);
}

.ic.ic-chevron-right {
  background-image: url(./assets/ic-chevron-right.svg);
}

.ic.ic-title {
  background-image: url(./assets/ic-title.svg);
}

.ic.ic-checklist {
  background-image: url(./assets/ic-checklist.svg);
}

.ic.ic-close-circle {
  background-image: url(./assets/ic-close-circle.svg);
}

.ic.ic-edit.small {
  width: 17px;
  height: 17px;
  margin-right: -1px;
  margin-bottom: 1px;
}

@media screen and (min-width: 1171px) {
  .user-btn:hover .window-container {
    visibility: visible;
    pointer-events: all;
  }
}

@media screen and (max-width: 1170px) {
  header .header-actions {
    position: absolute;
    top: 80px;
    right: 32px;
    background: #1F1D2C;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 0;
    gap: 8px;
    z-index: 4;
    border-radius: 16px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s ease-in;
  }
  header .header-container .btn,
  header .social-btn {
    display: none;
  }
  header .header-actions[open] {
    visibility: visible;
    opacity: 1;
  }
  .header-actions .nav-link {
    text-align: left;
    margin: 0;
    padding: 10px 24px;
  }
  .header-actions .header-btn {
    display: none;
  }
  header .nav-link.router-link-active:after {
    margin-top: -6px;
  }
  header .header-actions .btn,
  header .header-actions .btn.ghost {
    padding: 0 24px;
    height: 40px;
    width: 100%;
    justify-content: flex-start;
  }
  .header-actions .nav-link.mobile {
    display: block;
  }
  header .icon-button {
    display: flex;
  }
  .wrapper {
    padding: 0 32px;
  }
  header .user-btn {
    position: static;
  }
  .window-container {
    top: 0;
    right: 0;
    left: auto;
    padding-top: 0;
  }
}

@media screen and (max-width: 1024px) {
  footer .footer-container {
    flex-wrap: wrap;
  }
  footer .footer-logo {
    width: 128px;
    margin: 0 0 24px;
  }
  footer .col {
    margin-top: 16px;
  }
  footer .col.m {
    width: 50%;
  }
  footer .col.m:first-child {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 48px;
  }
  footer .footer-bottom {
    display: block;
    text-align: center;
  }
  footer .copyright {
    margin-top: 24px;
  }
}

@media screen and (max-width: 880px) {
  .wrapper {
    padding: 0 24px;
  }
}

@media screen and (max-width: 640px) {
  .overlay {
    padding: 16px;
  }
  .overlay .dialog {
    padding: 32px 16px;
  }
  footer .col.m,
  footer .col.x {
    width: 100%;
    text-align: center;
  }
  header .nav-link.mobile-nav {
    display: block;
  }
  header nav {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .page-title {
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
  .page-title h1:after {
    left: 0;
    margin: auto;
  }
}
</style>
