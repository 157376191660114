<template>
  <span :class="`icon ${size}`" v-html="svg"></span>
</template>

<script>
export default {
  name: 'Icon',
  props: {
    name: String,
    size: String,
  },
  data() {
    return {
      svg: null,
    };
  },
  methods: {
    async getIcon() {
      const data = await fetch(`/images/ic-${this.name}.svg`);
      this.svg = await data.text();
    },
  },
  mounted() {
    this.getIcon();
  },
};
</script>

<style scoped>
.icon {
  display: inline-block;
  vertical-align: middle;
  width: 24px;
  height: 24px;
}

.icon.s {
  width: 18px;
  height: 18px;
}

.icon.l {
  width: 32px;
  height: 32px;
}

.icon >>> svg {
  display: block;
  width: 100%;
  height: 100%;
}
</style>
