export class APIClient {
  baseUrl = 'https://wsip.app/api';

  user = null;

  setUser(user) {
    this.user = user;
  }

  async request(path, options = {}) {
    const token = await this.user.getIdToken();
    const response = await fetch(`${this.baseUrl}${path}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token,
      },
      ...options,
    });
    if (response.ok) {
      return response.json();
    }
    const error = await response.json();
    throw error;
  }

  get(path, params = {}) {
    const qs = new URLSearchParams(params).toString();
    return this.request(`${path}${qs ? `?${qs}` : ''}`, {
      method: 'GET',
    });
  }

  post(path, body) {
    return this.request(path, {
      method: 'POST',
      body: body && JSON.stringify(body),
    });
  }

  delete(path, params = {}) {
    const qs = new URLSearchParams(params).toString();
    return this.request(`${path}${qs ? `?${qs}` : ''}`, {
      method: 'DELETE',
    });
  }

  async beacon(path, data = {}) {
    const accessToken = await this.user.getIdToken();
    return navigator.sendBeacon(`${this.baseUrl}${path}`, JSON.stringify({
      accessToken,
      ...data,
    }));
  }
}

export const client = new APIClient();
