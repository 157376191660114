<template>
  <router-link :class="`post-item ${size}`" :to="`/news/${post.slug}`">
    <img v-if="post.picture" :src="post.picture.url" :alt="post.picture.file" class="post-image">
    <div class="post-content">
      <div class="post-title">{{ post.title }}</div>
      <div class="post-author" v-if="post.author">By {{ post.author.name }}</div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'PostItem',
  props: {
    post: Object,
    size: {
      type: String,
      default: 'default',
    },
  },
};
</script>

<style scoped>
.post-item {
  position: relative;
  display: block;
  padding-top: 56.25%;
  overflow: hidden;
  border-radius: 16px;
  background: #1F1D2C;
}

.post-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.post-content {
  position: absolute;
  left: 24px;
  bottom: 20px;
  padding: 16px 24px;
  background: rgba(31, 29, 44, 0.84);
  border-radius: 8px;
  font-size: 14px;
  line-height: 1;
  max-width: calc(100% - 48px);
}

.post-title {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 2px;
}

.post-item.small .post-title {
  font-size: 20px;
}

@media screen and (max-width: 580px) {
  .post-item:not(.small) .post-title {
    font-size: 24px;
  }
  .post-item.small .post-title {
    font-size: 18px;
    margin-bottom: 8px;
  }
  .post-content {
    left: 12px;
    bottom: 12px;
    right: 12px;
    max-width: none;
    padding: 12px 16px;
  }
}
</style>
