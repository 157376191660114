<template>
  <div class="poll-item-container">
    <div class="poll-item" :layout="layout">
      <div class="poll-item-header">
        <i class="ic ic-controller"></i>
        <div class="poll-item-title">
          <span>
            {{ poll.title }}
          </span>
          <div class="poll-total-votes" v-if="poll.settings && !poll.settings.hide_votes">
            <b>
              <Number :value="totalVotes" />
            </b>
            vote{{ totalVotes > 1 ? 's' : '' }}
          </div>
        </div>
      </div>
      <!-- <div class="poll-item-vote" v-if="votedGames.length">
        You voted for <b>{{ votedGames.join(', ') }}</b>
      </div> -->
      <div class="poll-item-results">
        <div class="game-card" v-for="(vote, index) in votes" :active="votedGameIds.includes(vote.id)" :key="`vote-${vote.id}`" :clickable="clickable" @click="clickable && !votedGameIds.includes(vote.id) && $emit('select', vote)">
          <img class="game-card-cover" :src="vote.box_art_url" :alt="vote.name">
          <div class="game-card-content">
            <div class="game-card-vote-cast" v-if="layout === 'item'">
              <i class="ic small ic-check"></i>
              Vote cast.
            </div>
            <div class="game-card-title">
              <div class="game-card-index" v-if="layout === 'main'">#{{ index + 1 }}</div>
              {{vote.name}}
              <span class="game-card-votes" v-if="layout === 'main' && poll.settings && !poll.settings.hide_votes"><b><Number :value="vote.count" /></b> vote{{ vote.count > 1 ? 's' : '' }}</span>
            </div>
            <div class="game-card-bar">
              <div class="game-card-thumb" :style="{ width: `${totalVotes ? Math.round((vote.count / totalVotes) * 100) : 0}%` }">
                <i :style="{ width: `${gameBarSize}px` }"></i>
              </div>
            </div>
            <div class="game-card-count">
              {{ totalVotes ? Math.round(vote.count / totalVotes * 100) : 0 }}%
            </div>
          </div>
          <div class="game-card-actions">
            <button v-if="!pollEnded && recastGames && recastGames.includes(vote.id) && layout === 'main'" title="Recast your vote" class="icon-button l recast-button" type="button" :disabled="loading" @click.stop="recastVote(vote.id)">
              <i class="ic small ic-refresh"></i>
            </button>
            <button title="Exclude this game" v-if="canEdit && layout !== 'item'" class="icon-button l" type="button" :disabled="loading" @click.stop="excludedGame = vote">
              <i class="ic small ic-nuke"></i>
            </button>
          </div>
          <div class="game-card-voted" v-if="layout === 'main'">
            <div class="ic small ic-check"></div>
            You voted for this game.
          </div>
        </div>
      </div>
      <div class="poll-item-info">
        <div class="poll-item-panel" v-if="layout === 'main'">
          <div class="poll-share">
            <div class="poll-share-content">
              <div class="poll-share-title">Share it now!</div>
              <div>Share this poll with your friends!</div>
            </div>
            <button class="icon-button l" type="button" @click="shareOpen = true">
              <i class="ic small ic-share"></i>
            </button>
          </div>
        </div>
        <div class="poll-item-content">
          <div v-if="layout !== 'item'" class="poll-item-status">Votes are <b :class="`status ${pollEnded ? 'inactive' : 'active'}`">{{ pollEnded ? 'closed' : 'open' }}</b></div>
          <div>Created on <b>{{ pollDate[0] }}</b> at <b>{{ pollDate[1] }}</b></div>
          <div class="poll-item-data" v-if="pollEndDate">Ends on <b>{{ pollEndDate[0] }}</b> at <b>{{ pollEndDate[1] }}</b></div>
          <div class="poll-item-data" v-else>No end date, you can vote at any time</div>
          <div v-if="layout === 'item' && poll.user">by <b>{{ poll.user.name }}</b></div>
          <div v-if="layout === 'edit'" class="poll-item-count"><b>{{ totalVotes }}</b> vote{{ totalVotes > 1 ? 's' : '' }} so far...</div>
        </div>
        <div class="poll-item-link" v-if="layout === 'item'">
          <router-link class="btn" :to="`/polls/${poll.id}`">Full poll</router-link>
          <b :class="`status ${pollEnded ? 'inactive' : 'active'}`">{{ pollEnded ? 'Closed' : 'Open' }}</b>
        </div>
        <div class="poll-item-actions" v-if="layout !== 'main'">
          <button class="icon-button l" type="button" @click="shareOpen = true">
            <i class="ic small ic-share"></i>
          </button>
          <button v-if="canEdit && layout !== 'edit'" class="icon-button l" type="button" @click.stop="$router.push(`/polls/${poll.id}/edit`)">
            <i class="ic small ic-edit"></i>
          </button>
          <button v-if="canEdit" class="icon-button l danger" type="button" @click="deleteOpen = true">
            <i class="ic small ic-delete-r"></i>
          </button>
        </div>
        <div v-else>
          <div class="poll-info-card">
            <div class="poll-info-card-title">
              <i class="ic ic-refresh-w small"></i>
              <span>Recast your vote</span>
            </div>
            <div class="poll-info-card-content">
              If you made a mistake or changed your mind, you can recast your vote(s). However, you only have 5 minutes to do so. After this period, you will not be able to recast a vote.
            </div>
          </div>
          <div class="poll-edit-actions" v-if="canEdit">
            <button v-if="layout !== 'edit'" class="btn default outlined" type="button" @click.stop="$router.push(`/polls/${poll.id}/edit`)">
              Edit poll
              <i class="ic small ic-edit"></i>
            </button>
            <button class="btn outlined danger" type="button" @click="deleteOpen = true">
              Delete poll
              <i class="ic small ic-delete-r"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="overlay" v-if="shareOpen" @click="shareOpen = false">
      <div class="dialog" @click.stop>
        <div class="dialog-title">Share it now!</div>
        <div class="dialog-content">
          Copy and share the link below
        </div>
        <div class="share-box">
          <div class="share-link">{{ pollUrl }}</div>
          <button class="btn" @click="copyLink">Copy</button>
        </div>
        <div class="dialog-message" :style="{ opacity: +linkCopied }">You've copied the link in your clipboard, all good!</div>
      </div>
    </div>
    <div class="overlay" v-if="deleteOpen" @click="deleteOpen = false">
      <div class="dialog" @click.stop>
        <div class="dialog-title">Wait wait!</div>
        <div class="dialog-content">
          Are you sure you want to delete this poll?
        </div>
        <div class="dialog-actions">
          <button class="btn" @click="deleteOpen = false" :disabled="loading">Take me back! This was a mistake...</button>
          <button class="btn outlined" @click="deletePoll" :disabled="loading">Yes, I'm sure. Delete this poll</button>
        </div>
      </div>
    </div>
    <div class="overlay" v-if="excludedGame" @click="excludedGame = null">
      <div class="dialog" @click.stop>
        <div class="dialog-title">Wait wait!</div>
        <div class="dialog-content">
          You are about to exclude <b>{{ excludedGame.name }}</b> from the poll.<br><br>
          All votes related to this game will be deleted.<br>
          Are you sure you want to exclude this game?
        </div>
        <div class="dialog-actions">
          <button class="btn" @click="excludedGame = null" :disabled="loading">Take me back! This was a mistake...</button>
          <button class="btn outlined" @click="excludeGame" :disabled="loading">Yes, I'm sure. Exclude this game</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import {
  collection, getDocs, onSnapshot, query, where,
} from 'firebase/firestore';
import { db } from '../api';
import { client } from '../api/api';
import Number from './Number.vue';

export default {
  name: 'PollItem',
  components: {
    Number,
  },
  props: {
    poll: Object,
    userId: String,
    isAdmin: Boolean,
    showUsername: Boolean,
    userVotes: Array,
    clickable: Boolean,
    highlight: Boolean,
    recastGames: Array,
    layout: {
      type: String,
      default: 'item',
    },
  },
  data() {
    return {
      vote: null,
      shareOpen: false,
      deleteOpen: false,
      linkCopied: false,
      loading: false,
      excludedGame: null,
      gameBarSize: 0,
    };
  },
  computed: {
    canEdit() {
      return this.isAdmin || this.poll.userId === this.userId;
    },
    pollDate() {
      const mom = moment(this.poll.created);
      return [mom.format('MMM D YYYY'), mom.format('h.mm a')];
    },
    pollEndDate() {
      if (!this.poll.ended_at) {
        return null;
      }
      const mom = moment(this.poll.ended_at.toDate());
      return [mom.format('MMM D YYYY'), mom.format('h.mm a')];
    },
    pollEnded() {
      return this.poll.ended_at && this.poll.ended_at.toDate() < new Date();
    },
    votedGameIds() {
      return this.userVotes || this.vote?.votes || [];
    },
    votedGames() {
      const votes = this.userVotes || this.vote?.votes;
      if (!votes) {
        return [];
      }
      return votes.map((id) => this.poll.games[id]?.title).filter((g) => g);
    },
    votes() {
      let results = [];
      if (this.poll.settings?.games) {
        results = this.poll.settings.games.map((id) => {
          const game = this.poll.games[id];
          if (game) {
            return {
              ...game,
              box_art_url: game.box_art_url.replace('{width}', this.layout === 'main' ? '136' : '56').replace('{height}', this.layout === 'main' ? '190' : '78'),
              count: this.poll.votes[id] || 0,
            };
          }
          return null;
        }).filter((g) => g).sort((a, b) => b.count - a.count);
      } else {
        results = Object.entries(this.poll.votes).reduce((votes, [key, count]) => {
          const game = this.poll.games[key];
          if (game && count > 0) {
            votes.push({
              ...game,
              box_art_url: game.box_art_url.replace('{width}', this.layout === 'main' ? '136' : '56').replace('{height}', this.layout === 'main' ? '190' : '78'),
              count,
            });
          }
          return votes;
        }, []).sort((a, b) => b.count - a.count);
      }
      if (this.layout !== 'main') {
        results = results.slice(0, 3);
      }
      return results;
    },
    totalVotes() {
      return Object.entries(this.poll.votes).reduce((votes, [key, count]) => {
        const game = this.poll.games[key];
        if (game && count > 0) {
          votes += count;
        }
        return votes;
      }, 0);
    },
    pollUrl() {
      return `${window.location.origin}/polls/${this.poll.id}`;
    },
  },
  methods: {
    copyLink() {
      if (this.linkCopied) {
        return;
      }
      this.linkCopied = true;
      navigator.clipboard.writeText(this.pollUrl);
      setTimeout(() => {
        this.linkCopied = false;
      }, 3000);
    },
    async deletePoll() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      await client.delete(`/polls/${this.poll.id}`);
      this.loading = false;
      this.deleteOpen = false;
    },
    async recastVote(gameId) {
      if (this.loading) {
        return;
      }
      this.loading = true;
      await client.post(`/polls/${this.poll.id}/recast`, { gameId });
      this.loading = false;
    },
    async excludeGame() {
      if (this.loading) {
        return;
      }
      this.loading = true;
      await client.post(`/polls/${this.poll.id}/exclude`, { gameId: this.excludedGame.id });
      this.loading = false;
      this.excludedGame = null;
    },
    async getVotes() {
      if (this.votesRef) {
        this.votesRef();
      }
      if (!this.userId) {
        return;
      }
      const q = query(
        collection(db, 'votes'),
        where('userId', '==', this.userId),
        where('pollId', '==', this.poll.id),
      );
      if (this.layout === 'main') {
        this.votesRef = onSnapshot(q, (docs) => {
          this.vote = {
            votes: docs.docs.map((doc) => doc.get('gameId')),
          };
        });
      } else {
        const docs = await getDocs(q);
        this.vote = {
          votes: docs.docs.map((doc) => doc.get('gameId')),
        };
      }
    },
    sizeBar() {
      const bar = this.$el.querySelector('.game-card-bar');
      if (bar) {
        this.gameBarSize = bar.clientWidth;
      }
    },
  },
  mounted() {
    if (!this.userVotes && this.userId !== this.poll.userId) {
      this.getVotes();
    }
    window.addEventListener('resize', this.sizeBar);
    window.dispatchEvent(new Event('resize'));
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.sizeBar);
    if (this.votesRef) {
      this.votesRef();
    }
  },
};
</script>

<style scoped>
.poll-item-container {
  display: flex;
  flex-direction: column;
}

.poll-item {
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: stretch;
  flex-direction: column;
  background: #1F1D2C;
  text-align: left;
  border-radius: 16px;
  padding: 24px 32px 32px;
}

.poll-item[layout="edit"] {
  flex-direction: column-reverse;
}

.poll-item[layout="main"] {
  flex-direction: row;
  background: none;
  padding: 0;
}

.poll-item[layout="edit"] .poll-item-header,
.poll-item[layout="edit"] .poll-item-data,
.poll-item[layout="edit"] .poll-item-status,
.poll-item[layout="edit"] .poll-item-vote,
.poll-item[layout="main"] .poll-item-header,
.poll-item[layout="main"] .poll-item-vote {
  display: none;
}

.poll-item[layout="main"] .poll-item-results,
.poll-item[layout="edit"] .poll-item-results {
  margin-bottom: 0;
}

.poll-item-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 12px;
}

.poll-item-title {
  flex-grow: 1;
  font-size: 20px;
  font-weight: 600;
  color: #FC0;
}

.poll-item-title > span {
  display: block;
}

.poll-item-actions {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
}

.poll-item-info .icon-button {
  background: #393652;
}

.poll-item-info .icon-button.danger {
  background: rgba(242, 114, 114, 0.16);
}

.poll-item-info .icon-button .ic,
.game-card-actions .icon-button .ic {
  width: 16px;
  height: 16px;
}

.poll-item-results {
  margin: 16px 0;
  flex-grow: 1;
}

.poll-item[layout="main"] .poll-item-results {
  margin: 0 24px 0 0;
}

.poll-item-vote {
  position: relative;
  padding: 14px 16px;
  border: 1px solid #F36;
  border-radius: 24px;
  margin-top: 24px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.poll-total-votes {
  font-weight: normal;
  color: #fff;
  font-size: 14px;
}

.poll-item-info {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  color: rgba(255, 255, 255, 0.69);
}

.poll-item[layout="item"] .poll-item-info {
  flex-wrap: wrap;
  align-items: center;
}

.poll-item[layout="main"] .poll-item-info {
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 432px;
  gap: 24px;
  flex-shrink: 0;
}

.poll-item-status,
.poll-item-count {
  color: #fff;
  font-weight: bold;
}

.poll-item-content {
  flex-grow: 1;
}

.poll-item[layout="item"] .poll-item-content {
  width: 100%;
  margin-bottom: 12px;
}

.poll-item[layout="main"] .poll-item-content {
  padding: 0 32px;
  flex-grow: 0
}

.poll-edit-actions {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 24px;
  padding: 0 32px;
  margin-top: 8px;
}

.poll-item-content b {
  color: #fff;
}

.poll-item-content b.inactive,
.poll-item-link b.inactive {
  color: #F27272;
}

.poll-item-content b.active,
.poll-item-link b.active {
  color: #FFEE00;
}

.poll-item-content > *:not(:last-child) {
  margin-bottom: 3px;
}

.poll-item-link {
  display: flex;
  flex-grow: 1;
  gap: 12px;
  align-items: center;
}

.poll-item-link b {
  font-size: 14px;
}

.poll-item-link .btn {
  font-weight: bold;
}

.poll-share {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 32px;
  background: #1F1D2C;
  border-radius: 16px;
  color: #fff;
}

.poll-share .poll-share-title {
  color: #FC0;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
}

.game-card {
  position: relative;
  display: flex;
  align-items: center;
  padding: 6px 0;
  border: 1px solid transparent;
  border-radius: 8px;
  margin: 0 -32px -1px;
  padding: 6px 32px;
}

.game-card[clickable]:not([active]) {
  cursor: pointer;
}

.game-card[active] {
  border-color: #FC0;
}

.poll-item[layout="main"] .game-card {
  margin: 0 0 12px;
  padding: 0 32px 0 0;
  background: #1F1D2C;
  border-radius: 16px;
  overflow: hidden;
}

.poll-info-card {
  padding: 16px 32px;
  background: rgba(31, 29, 44, 0.46);
  border-radius: 16px;
  margin-bottom: 32px;
}

.poll-info-card .poll-info-card-title {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #fff;
  margin-bottom: 12px;
}

.poll-info-card .poll-info-card-content {
  font-style: italic;
  font-size: 14px;
}

.game-card-vote-cast {
  position: absolute;
  display: none;
  bottom: 100%;
  left: 0;
  font-size: 14px;
  color: #FC0;
  align-items: center;
  font-style: italic;
  font-weight: 400;
  gap: 4px;
  margin-bottom: -2px;
}

.game-card[active] .game-card-vote-cast {
  display: flex;
}

.game-card .game-card-voted {
  position: absolute;
  display: none;
  right: 32px;
  bottom: 24px;
  font-size: 14px;
  color: #FC0;
}

.game-card[active] .game-card-voted {
  display: block;
}

.game-card .game-card-actions {
  position: absolute;
  display: flex;
  top: 16px;
  right: 32px;
  gap: 8px;
}

.game-card .game-card-actions .icon-button {
  background-color: #393652;
}

.game-card .recast-button {
  visibility: hidden;
  opacity: 0.8;
}

.game-card[active] .recast-button {
  visibility: visible;
}

.game-card .game-card-title {
  position: relative;
  flex-grow: 1;
  max-height: 38px;
  overflow: hidden;
}

.game-card .game-card-index {
  color: #FC0;
  font-size: 24px;
  font-family: Chantal;
  font-weight: 600;
  text-transform: uppercase;
}

.poll-item[layout="main"] .game-card .game-card-title {
  font-size: 24px;
  font-weight: 700;
  max-height: none;
}

.game-card .game-card-votes {
  font-size: 16px;
  font-weight: normal;
  margin-left: 8px;
}

.game-card .game-card-votes b {
  font-weight: 600;
}

.game-card .game-card-vote {
  display: none;
  position: absolute;
  bottom: 100%;
  left: 0;
  right: 0;
  font-size: 12px;
  font-weight: 300;
  font-family: chantal;
}

.game-card[active] .game-card-vote {
  display: block;
}

.game-card .game-card-content {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  gap: 12px;
  font-weight: 600;
  margin-left: 16px;
  padding: 8px 0;
}

.poll-item[layout="main"] .game-card .game-card-content {
  align-items: flex-end;
  padding-bottom: 12px;
}

.poll-item[layout="main"] .game-card .game-card-count {
  font-size: 24px;
  line-height: 1;
}

.game-card .game-card-cover {
  position: relative;
  width: 56px;
  border-radius: 2px;
}

.poll-item[layout="main"] .game-card .game-card-cover {
  width: 136px;
}

.game-card .game-card-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 5px;
}

.game-card .game-card-thumb {
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: 7px;
  transition: width 0.2s ease-out;
  overflow: hidden;
}

.poll-item[layout="main"] .game-card .game-card-bar {
  height: 8px;
}

.game-card .game-card-bar i {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background: #F36;
  background: linear-gradient(270deg, #F36 0%, #F2A81D 33%, #FC0 64.5%, #6CF 100%);
}

.game-card .game-card-count {
  font-weight: 700;
}

.game-card[active] .game-card-count {
  color: #FC0;
}

@media screen and (max-width: 1024px) {
  .poll-item[layout="main"] {
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }
  .poll-item[layout="main"] .poll-item-results {
    margin-right: 0;
  }
  .poll-item[layout="main"] .poll-item-info {
    max-width: 100%;
  }
  .poll-item[layout="main"] .poll-item-content {
    text-align: center;
  }
  .poll-edit-actions {
    flex-direction: row;
    justify-content: center;
  }
  .poll-item[layout="main"] .game-card .game-card-title {
    font-size: 20px;
  }
  .poll-item[layout="main"] .game-card .game-card-cover {
    width: 96px;
  }
  .poll-item[layout="main"] .game-card .game-card-content {
    margin-bottom: 26px;
  }
  .game-card .game-card-voted {
    bottom: 10px;
  }
}

@media screen and (max-width: 680px) {
  .poll-item[layout="main"] .game-card .game-card-title {
    font-size: 18px;
  }
  .game-card .game-card-votes {
    display: block;
    margin-left: 0;
  }
  .poll-item[layout="main"] .game-card {
    padding-right: 24px;
    align-items: stretch;
  }
  .poll-item[layout="main"] .game-card .game-card-cover {
    object-fit: cover;
  }
  .poll-item[layout="main"] .game-card .game-card-actions,
  .poll-item[layout="main"] .game-card .game-card-voted {
    right: 24px;
  }
  .poll-item[layout="main"] .game-card .game-card-actions {
    top: 12px;
  }
  .poll-item[layout="main"] .game-card .game-card-content {
    padding-top: 16px;
    margin-bottom: 40px;
  }
  .poll-item[layout="main"] .poll-item-content {
    padding: 0;
  }
}

@media screen and (max-width: 420px) {
  .poll-item[layout="main"] .game-card {
    padding-right: 16px;
  }
  .poll-item[layout="main"] .game-card .game-card-actions,
  .poll-item[layout="main"] .game-card .game-card-voted {
    right: 16px;
  }
}
</style>
