<template>
  <div class="poll-card">
    <router-link class="poll-card-title" :to="`/polls/${poll.id}`">{{ poll.title }}</router-link>
    <div class="poll-card-content">
      <div class="poll-card-info">
        <div class="poll-card-author" v-if="poll.user">
          Created by <b>{{ poll.user.name }}</b>
        </div>
        <div class="poll-card-votes">
          <b><Number :value="totalVotes" /></b>
          vote{{ totalVotes > 1 ? 's' : '' }}
        </div>
      </div>
      <img v-if="poll.user && poll.user.avatar" :src="poll.user.avatar" :alt="poll.user.name" class="poll-card-avatar">
    </div>
  </div>
</template>

<script>
import Number from './Number.vue';

export default {
  name: 'PollCard',
  props: {
    poll: Object,
  },
  components: {
    Number,
  },
  computed: {
    totalVotes() {
      return Object.entries(this.poll.votes).reduce((votes, [key, count]) => {
        const game = this.poll.games[key];
        if (game && count > 0) {
          votes += count;
        }
        return votes;
      }, 0);
    },
  },
};
</script>

<style scoped>
.poll-card {
  display: flex;
  flex-direction: column;
  background: rgba(31, 29, 44, 0.33);
  padding: 24px 24px 16px;
  border-radius: 16px;
  height: 100%;
  transition: transform 0.2s ease-out;
}

.poll-card:hover {
  transform: translateY(-4px);
}

.poll-card-title {
  display: block;
  color: #FC0;
  font-size: 22px;
  font-weight: 600;
  flex-grow: 1;
}

.poll-card-author {
  color: rgba(255, 255, 255, 0.69);
}

.poll-card-author b {
  color: #fff;
}

.poll-card-content {
  display: flex;
  align-items: center;
  background: url(../assets/ic-dashed.svg) repeat-x top left/100% auto;
  margin-top: 8px;
  padding-top: 8px;
}

.poll-card-info {
  flex-grow: 1;
}

.poll-card-avatar {
  display: block;
  width: 32px;
  height: 32px;
  object-fit: cover;
  border-radius: 50%;
}

.poll-card-author {
  margin-bottom: 2px;
}
</style>
