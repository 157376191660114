<template>
  <span>{{ formattedValue }}</span>
</template>

<script>
export default {
  name: 'Number',
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    formattedValue() {
      if (Number.isNaN(this.value)) {
        return '';
      }
      return new Intl.NumberFormat().format(this.value);
    },
  },
};
</script>
