import Vue from 'vue';
import Vuex from 'vuex';
import { signInAnonymously, signInWithCustomToken, onAuthStateChanged } from 'firebase/auth';
import { doc, onSnapshot } from 'firebase/firestore';
import { auth, db } from '../api';
import { client } from '../api/api';

Vue.use(Vuex);

const isDev = window.location.host.includes('localhost')
  || window.location.host.includes('192.168.40.195')
  || window.location.host.includes('wsip-236db-staging');

let promptTO;

const store = new Vuex.Store({
  state: {
    user: isDev ? {
      id: 'P1AlAWHqLhZlHYLBX7Mkiyq8u8l1',
      name: 'Holypony',
      avatar: 'https://static-cdn.jtvnw.net/jtv_user_pictures/holypony-profile_image-1e43c263d7b0cff0-300x300.png',
      type: 'twitch',
      admin: true,
    } : undefined,
    authUser: null,
    prompt: null,
    promptOpen: false,
  },
  mutations: {
    setUser(state, user) {
      state.user = user || null;
    },
    setAuthUser(state, user) {
      state.authUser = user || null;
    },
    setPrompt(state, prompt) {
      clearTimeout(promptTO);
      state.prompt = prompt;
      state.promptOpen = true;
      promptTO = setTimeout(() => {
        state.promptOpen = false;
        setTimeout(() => {
          state.prompt = null;
        }, 250);
      }, 5000);
    },
  },
});

const params = new URLSearchParams(window.location.search);
let token = params.get('token');
let userRef;

if (token && window.history.pushState) {
  params.delete('token');
  const qs = params.toString();
  const url = `${window.location.origin}${window.location.pathname}${qs ? `?${qs}` : ''}`;
  window.history.pushState({ path: url }, '', url);
}

onAuthStateChanged(auth, async (authState) => {
  try {
    if (userRef) {
      userRef();
    }
    let authUser;
    if (token) {
      const session = await signInWithCustomToken(auth, token);
      token = null;
      authUser = session.user;
    } else if (authState) {
      authUser = authState;
    } else {
      const session = await signInAnonymously(auth);
      authUser = session.user;
    }
    store.commit('setAuthUser', authUser);
    client.setUser(authUser);
    if (!isDev) {
      userRef = onSnapshot(doc(db, `users/${authUser.uid}`), (snap) => {
        store.commit('setUser', snap.data());
      });
    }
    client.post('/session');
  } catch (error) {
    token = null;
    console.warn(error.message);
  }
});

export default store;
