import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { getFunctions, httpsCallable } from 'firebase/functions';

const firebaseConfig = {
  apiKey: 'AIzaSyAjbRjrop9V06X9aOx9OyeO5G5_VoHmjGE',
  authDomain: 'wsip-236db.firebaseapp.com',
  projectId: 'wsip-236db',
  storageBucket: 'wsip-236db.appspot.com',
  messagingSenderId: '829616687582',
  appId: '1:829616687582:web:c571afacdfca57eb12354f',
};

export const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const functions = getFunctions(app, 'europe-west2');

export const request = async (action, payload) => {
  const { data } = await httpsCallable(functions, action)(payload);
  return data;
};
