export const removeSearchParam = (param, value) => {
  const params = new URLSearchParams(window.location.search);
  if (value ? params.get(param) !== value : !params.has(param)) {
    return false;
  }
  params.delete(param);
  const qs = params.toString();
  const url = `${window.location.origin}${window.location.pathname}${qs ? `?${qs}` : ''}`;
  window.history.pushState({ path: url }, '', url);
  return true;
};
