<template>
  <button class="toggle-button" type="button" :active="value" @click="$emit('input', !value)"></button>
</template>

<script>
export default {
  name: 'ToggleButton',
  props: {
    value: Boolean,
  },
};
</script>

<style scoped>
.toggle-button {
  position: relative;
  width: 26px;
  height: 14px;
  background: #d9d9d9;
  border-radius: 7px;
  padding: 0;
  margin: 0;
  flex-shrink: 0;
  transition: background 0.2s ease-out;
}

.toggle-button[active] {
  background: #FC0;
}

.toggle-button:after {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  top: 3px;
  left: 3px;
  transition: transform 0.2s ease-out;
  background: #1F1D2C;
  border-radius: 50%;
}

.toggle-button[active]:after {
  transform: translateX(12px);
}
</style>
