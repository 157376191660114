import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/news/:id',
    name: 'blog-post',
    component: () => import(/* webpackChunkName: "blog-post" */ '../views/BlogPost.vue'),
  },
  {
    path: '/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "news" */ '../views/News.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
  },
  {
    path: '/profile/polls',
    name: 'profile-polls',
    component: () => import(/* webpackChunkName: "profile-polls" */ '../views/profile/ProfilePolls.vue'),
  },
  {
    path: '/profile/votes',
    name: 'profile-votes',
    component: () => import(/* webpackChunkName: "profile-votes" */ '../views/profile/ProfileVotes.vue'),
  },
  {
    path: '/polls',
    name: 'polls',
    component: () => import(/* webpackChunkName: "polls" */ '../views/Polls.vue'),
  },
  {
    path: '/polls/new',
    name: 'poll-new',
    component: () => import(/* webpackChunkName: "poll-new" */ '../views/PollEdit.vue'),
  },
  {
    path: '/polls/:id',
    name: 'poll',
    component: () => import(/* webpackChunkName: "poll" */ '../views/Poll.vue'),
  },
  {
    path: '/polls/:id/edit',
    name: 'poll-edit',
    component: () => import(/* webpackChunkName: "poll-edit" */ '../views/PollEdit.vue'),
  },
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    component: () => import(/* webpackChunkName: "privacy-policy" */ '../views/TemplatePage.vue'),
  },
  {
    path: '/cookie-policy',
    name: 'cookie-policy',
    component: () => import(/* webpackChunkName: "cookie-policy" */ '../views/TemplatePage.vue'),
  },
  {
    path: '/patch-notes',
    name: 'patch-notes',
    component: () => import(/* webpackChunkName: "patch-notes" */ '../views/TemplatePage.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
