<template>
  <div class="page home">
    <section class="news" v-if="posts.length">
      <div class="wrapper">
        <div class="container">
          <div class="main-post" v-if="windowWidth > 880">
            <PostItem :post="posts[0]" />
          </div>
          <div class="swiper" ref="blogPosts" v-else>
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="post in posts" :key="post.slug">
                <PostItem :post="post" size="small" />
              </div>
            </div>
          </div>
          <div class="side-posts" v-if="windowWidth > 880 && posts.length > 1">
            <div class="item" v-for="post in posts.slice(1)" :key="post.slug">
              <PostItem :post="post" size="small" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="recent-polls" v-if="recentPolls.length">
      <div class="wrapper">
        <h2 class="section-title">
          <i class="ic ic-controller"></i>
          Most recent polls
        </h2>
        <div ref="recentPolls" class="swiper">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="poll in recentPolls.slice(0, 4)" :key="poll.id">
              <PollCard :poll="poll" />
            </div>
          </div>
        </div>
        <div class="section-actions">
          <router-link class="btn outlined" to="/polls">Browse public polls</router-link>
        </div>
      </div>
    </section>
    <section class="popular-games" v-if="popularGames.length">
      <div class="wrapper">
        <h2 class="section-title">
          <i class="ic ic-controller"></i>
          Popular games right now
        </h2>
        <div class="swiper" ref="popularGames">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="game in popularGames.slice(0, 5)" :key="game.id">
              <GameCard :game="game" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="popular-polls" v-if="popularPolls.length">
      <div class="wrapper">
        <h2 class="section-title">
          <i class="ic ic-controller"></i>
          Popular polls
        </h2>
        <div class="swiper" ref="popularPolls">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="poll in popularPolls.slice(0, 3)" :key="poll.id">
              <PollItem :poll="poll" show-username highlight :user-id="user && user.id" :is-admin="user && user.admin" />
            </div>
          </div>
        </div>
        <div class="section-actions">
          <router-link class="btn outlined" to="/polls">Browse public polls</router-link>
        </div>
      </div>
    </section>
    <section class="highlight">
      <div class="wrapper">
        <div class="container">
          <div class="content">
            <div class="subtitle">What Should I Play's Recommendations</div>
            <h2>GAmes<br>YOU SHOULD PLAY!</h2>
            <div class="actions">
              <router-link class="btn" to="/polls/new">Create a poll</router-link>
              <router-link class="btn outlined" to="/polls">Browse public polls</router-link>
            </div>
          </div>
          <div class="list">
            <div class="item" v-for="game in highlightGames.slice(0, 3)" :key="`h-${game.id}`">
              <GameCard :game="{ ...game, votes: 0 }" />
            </div>
          </div>
        </div>
      </div>
    </section>
   <section class="leaderboard" v-if="leaderboards">
      <div class="wrapper">
        <h2 class="section-title">
          <i class="ic ic-controller"></i>
          Leaderboards
        </h2>
        <div class="swiper" ref="leaderboard">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-if="leaderboards.pollers_all.length">
              <UserList :list="leaderboards.pollers_all" title="Top pollers" subtitle="All time" />
            </div>
            <div class="swiper-slide" v-if="leaderboards.pollers_30.length">
              <UserList :list="leaderboards.pollers_30" title="Top pollers" subtitle="Last 90 days" />
            </div>
            <div class="swiper-slide" v-if="leaderboards.voters_all.length">
              <UserList :list="leaderboards.voters_all" title="Top voters" subtitle="All time" />
            </div>
          </div>
        </div>
        <!-- <div class="section-actions">
          <router-link class="btn outlined" to="/leaderboards">Explore Leaderboards</router-link>
        </div> -->
      </div>
    </section>
  </div>
</template>

<script>
import {
  collection, onSnapshot, orderBy, query, where, limit,
} from 'firebase/firestore';
import { mapState } from 'vuex';
import { Swiper } from 'swiper';
import PollItem from '../components/PollItem.vue';
import PostItem from '../components/PostItem.vue';
import PollCard from '../components/PollCard.vue';
import GameCard from '../components/GameCard.vue';
import UserList from '../components/UserList.vue';
import { db } from '../api';

import 'swiper/swiper.css';
import { client } from '../api/api';

export default {
  name: 'Home',
  computed: {
    ...mapState(['user', 'authUser']),
    popularGames() {
      return Object.values(this.polls.reduce((list, poll) => {
        Object.entries(poll.votes).forEach(([gameId, count]) => {
          const game = poll.games[gameId];
          if (game) {
            if (!list[gameId]) {
              list[gameId] = {
                ...game,
                box_art_url: game.box_art_url?.replace('{width}', '260').replace('{height}', '347'),
                votes: 0,
              };
            }
            list[gameId].votes += count;
          }
        });
        return list;
      }, {})).sort((a, b) => b.votes - a.votes);
    },
    recentPolls() {
      return this.polls.filter((poll) => poll.totalVotes > 0);
    },
    popularPolls() {
      return [...this.polls]
        .filter((poll) => poll.totalVotes > 0 && (!poll.ended_at || poll.ended_at.toDate() > new Date()))
        .sort((a, b) => b.totalVotes - a.totalVotes);
    },
  },
  components: {
    PostItem,
    PollCard,
    GameCard,
    PollItem,
    UserList,
  },
  watch: {
    authUser() {
      this.getLeaderboards();
    },
  },
  data() {
    return {
      highlightGames: [
        {
          id: '519291',
          name: 'Black Myth: Wukong',
          box_art_url: 'https://static-cdn.jtvnw.net/ttv-boxart/519291_IGDB-260x347.jpg',
        },
        {
          id: '2109666737',
          name: 'Astro Bot',
          box_art_url: 'https://static-cdn.jtvnw.net/ttv-boxart/2109666737_IGDB-260x347.jpg',
        },
        {
          id: '223869970',
          name: 'Warhammer 40,000: Space Marine II',
          box_art_url: 'https://static-cdn.jtvnw.net/ttv-boxart/223869970_IGDB-260x347.jpg',
        },
      ],
      windowWidth: window.innerWidth,
      leaderboards: null,
      polls: [],
      posts: [],
    };
  },
  methods: {
    updateSize() {
      this.windowWidth = window.innerWidth;
      if (!this.blogPostsSwiper && this.$refs.blogPosts) {
        this.blogPostsSwiper = new Swiper(this.$refs.blogPosts, {
          slidesPerView: 1.15,
          spaceBetween: 12,
          slidesOffsetBefore: 24,
          slidesOffsetAfter: 24,
          enabled: true,
          breakpoints: {
            620: {
              slidesPerView: 2,
              enabled: false,
              slidesOffsetBefore: 0,
              slidesOffsetAfter: 0,
            },
          },
        });
      }
    },
    async getLeaderboards() {
      if (!this.authUser) {
        return;
      }
      this.leaderboards = await client.get('/leaderboards');
      this.$nextTick(() => {
        this.leaderboardSwiper = new Swiper(this.$refs.leaderboard, {
          slidesPerView: 1.1,
          spaceBetween: 12,
          slidesOffsetBefore: 24,
          slidesOffsetAfter: 24,
          enabled: true,
          breakpoints: {
            1170: {
              slidesPerView: 3,
              enabled: false,
              slidesOffsetBefore: 0,
              slidesOffsetAfter: 0,
              spaceBetween: 24,
            },
            880: {
              slidesPerView: 2.4,
              slidesOffsetBefore: 32,
              slidesOffsetAfter: 32,
              spaceBetween: 24,
              enabled: true,
            },
            620: {
              slidesPerView: 1.4,
              spaceBetween: 24,
              enabled: true,
            },
          },
        });
      });
    },
  },
  mounted() {
    this.postsRef = onSnapshot(query(collection(db, 'posts'), where('active', '==', true), where('featured', '==', true), limit(3)), (docs) => {
      this.posts = docs.docs.map((doc) => {
        const data = doc.data();
        return data;
      }).sort((a, b) => b.headline - a.headline);
    });
    this.pollsRef = onSnapshot(query(collection(db, 'polls'), where('public', '==', true), orderBy('created', 'desc')), (docs) => {
      this.polls = docs.docs.map((doc) => {
        const data = doc.data();
        const totalVotes = Object.entries(data.votes).reduce((votes, [key, count]) => {
          const game = data.games[key];
          if (game && count > 0) {
            votes += count;
          }
          return votes;
        }, 0);
        return {
          ...data,
          created: data.created.toDate(),
          updated: data.updated.toDate(),
          totalVotes,
        };
      });
      this.$nextTick(() => {
        this.recentPollsSwiper = new Swiper(this.$refs.recentPolls, {
          slidesPerView: 1.4,
          spaceBetween: 8,
          slidesOffsetBefore: 24,
          slidesOffsetAfter: 24,
          enabled: true,
          breakpoints: {
            1170: {
              slidesPerView: 4,
              enabled: false,
              slidesOffsetBefore: 0,
              slidesOffsetAfter: 0,
            },
            880: {
              slidesPerView: 3.4,
              slidesOffsetBefore: 32,
              slidesOffsetAfter: 32,
              enabled: true,
            },
            620: {
              slidesPerView: 2.4,
              enabled: true,
            },
          },
        });
        this.popularGamesSwiper = new Swiper(this.$refs.popularGames, {
          slidesPerView: 2.4,
          spaceBetween: 12,
          slidesOffsetBefore: 24,
          slidesOffsetAfter: 24,
          enabled: true,
          breakpoints: {
            1170: {
              slidesPerView: 5,
              enabled: false,
              slidesOffsetBefore: 0,
              slidesOffsetAfter: 0,
            },
            880: {
              slidesPerView: 4.4,
              slidesOffsetBefore: 32,
              slidesOffsetAfter: 32,
              enabled: true,
            },
            620: {
              slidesPerView: 3.4,
              enabled: true,
            },
          },
        });
        this.popularPollsSwiper = new Swiper(this.$refs.popularPolls, {
          slidesPerView: 1.2,
          spaceBetween: 12,
          slidesOffsetBefore: 24,
          slidesOffsetAfter: 24,
          enabled: true,
          breakpoints: {
            1170: {
              slidesPerView: 3,
              enabled: false,
              slidesOffsetBefore: 0,
              slidesOffsetAfter: 0,
              spaceBetween: 24,
            },
            880: {
              slidesPerView: 2.4,
              slidesOffsetBefore: 32,
              slidesOffsetAfter: 32,
              spaceBetween: 24,
              enabled: true,
            },
            620: {
              slidesPerView: 1.4,
              spaceBetween: 24,
              enabled: true,
            },
          },
        });
      });
    });
    this.getLeaderboards();
    window.addEventListener('resize', this.updateSize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateSize);
    if (this.pollsRef) {
      this.pollsRef();
    }
  },
};
</script>

<style scoped>
.swiper {
  margin-top: -4px;
}

.swiper .swiper-wrapper .swiper-slide {
  height: auto;
  padding-top: 4px;
}

section {
  margin-bottom: 48px;
}

.section-title {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 24px;
  text-transform: uppercase;
  margin-bottom: 24px;
}

.section-title:after {
  content: '';
  display: block;
  flex-grow: 1;
  height: 1px;
  background: rgba(255, 255, 255, 0.46);
}

.section-actions {
  display: flex;
  justify-content: center;
  gap: 12px;
  margin-top: 24px;
}

.news .container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.news .main-post {
  flex-grow: 1;
}

.news .side-posts {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 12px;
  width: 33.33333333%;
  padding-left: 12px;
}

.news .swiper {
  width: 100%;
  margin: 6px 0 0;
}

.recent-polls .container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.recent-polls .item {
  width: calc(25% - 6px);
}

.popular-games .container {
  display: flex;
  gap: 12px;
}

.popular-games .item {
  width: calc(20% - 10px);
}

.popular-polls .container {
  display: flex;
  gap: 24px;
}

.popular-polls .item {
  width: calc(33.3333333% - 16px);
}

.popular-polls .swiper-slide >>> .poll-item-container {
  height: 100%;
}

.highlight {
  background: #1F1D2C;
  padding: 40px 0;
}

.highlight .container {
  display: flex;
  align-items: center;
}

.highlight .content {
  width: 540px;
  max-width: 100%;
  text-align: center;
  padding: 0 24px;
}

.highlight .list {
  display: flex;
  flex-grow: 1;
  gap: 12px;
}

.highlight .list .item {
  width: calc(33.333333333% - 8px);
}

.highlight .subtitle {
  text-transform: uppercase;
  font-size: 12px;
}

.highlight h2 {
  font-family: chantal;
  font-size: 44px;
  color: #FC0;
  margin: 24px 0;
}

.highlight h2::after {
  content: '';
  display: block;
  width: 128px;
  height: 34.325px;
  background: url(../assets/title-art.svg) no-repeat center/contain;
  margin: auto;
}

.highlight .actions {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;
}

.leaderboard .container {
  display: flex;
  gap: 24px;
}

.leaderboard .item {
  width: calc(33.3333333% - 16px);
}

@media screen and (max-width: 1170px) {
  .swiper {
    margin: 0 -32px;
  }
}

@media screen and (max-width: 1024px) {
  .highlight .container {
    flex-direction: column;
    gap: 48px;
  }
}

@media screen and (max-width: 880px) {
  .news .container {
    flex-direction: column;
  }
  .news .main-post {
    width: 100%;
  }
  .news .side-posts {
    flex-direction: row;
    width: 100%;
    padding: 0;
    margin-top: 12px;
  }
  .news .side-posts .item {
    width: 49%;
  }
  .swiper {
    margin: 0 -24px;
  }
}

@media screen and (max-width: 620px) {
  .news .swiper {
    width: calc(100% + 48px);
    margin: 6px -24px 0;
  }
}

@media screen and (max-width: 480px) {
  .section-title {
    font-size: 20px;
  }
}
</style>
