<template>
  <div class="user-list-container">
    <div class="user-list-header">
      <div class="user-list-title">
        <b>{{ title }} -</b>
        {{ subtitle }}
      </div>
    </div>
    <div class="user-list">
      <div class="user-item" v-for="(user, index) in list" :key="user.id">
        <div class="user-item-index">#{{ index + 1 }}</div>
        <img :src="user.avatar" :alt="user.name" class="user-item-avatar">
        <div class="user-item-name">{{ user.name }}</div>
        <div class="user-item-votes">
          <b><Number :value="user.votes" /></b>
          vote{{ user.votes > 1 ? 's' : '' }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Number from './Number.vue';

export default {
  name: 'UserList',
  props: {
    list: Array,
    title: String,
    subtitle: String,
  },
  components: {
    Number,
  },
};
</script>

<style scoped>
.user-list-header {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 18px;
  margin-bottom: 12px;
}

.user-list-title b {
  font-size: 20px;
}

.user-item {
  display: flex;
  align-items: center;
  gap: 8px;
  background: rgba(31, 29, 44, 0.33);
  padding: 8px 24px;
  border-radius: 8px;
  margin-bottom: 6px;
}

.user-item .user-item-name {
  flex-grow: 1;
  font-size: 20px;
  font-weight: 600;
}

.user-item .user-item-index {
  font-family: Chantal;
  color: #FC0;
  font-size: 24px;
  font-weight: 600;
}

.user-item .user-item-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.user-item .user-item-votes {
  font-size: 14px;
}
</style>
